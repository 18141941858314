<template>
    <div>
      <LandingPage />
    </div>
  </template>
  
  <script>
  import LandingPage from "@/components/LandingPage.vue";
  
  export default {
    components: {
      LandingPage,
      
    },
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  </style>
  