<template>
  <nav class="nav-bar" :class="{ 'scrolled': isScrolled }">
    <ul class="nav-list">
      <li
        class="nav-item"
        v-for="(link, index) in links"
        :key="index"
        :style="getAnimationStyle(index)"
      >
        <router-link :to="link.path" class="nav-link">{{ link.name }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false,
      links: [
        { name: 'Home', path: '/' },
        { name: 'About Me', path: '/about' },
        { name: 'Contact', path: '/contact' },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    getAnimationStyle(index) {
      return {
        animation: `fadeSlideIn 0.5s ease-out ${index * 0.1}s`,
        'animation-fill-mode': 'both',
      };
    },
  },
};
</script>

<style scoped>
/* Navbar container */
.nav-bar {
  background-color: #ffffff;
  padding: 15px 30px;
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  transition: background-color 0.3s ease, padding 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Navbar scrolled effect */
.nav-bar.scrolled {
  background-color: #f8f8f8;
  padding: 10px 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

/* Nav list */
.nav-list {
  list-style-type: none;
  display: flex;
  gap: 25px;
  margin: 0;
  padding: 0;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  padding: 5px;
  transition: color 0.3s ease;
}

/* Hover effect for nav links */
.nav-link:hover {
  color: #007bff;
}

/* Simple underline effect */
.nav-link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -3px;
  left: 50%;
  background-color: #007bff;
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-link:hover::before {
  width: 100%;
  left: 0;
}

/* Fade and slide-in animation */
@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
